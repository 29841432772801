import React from 'react'

import Popover from '@mui/material/Popover'

export const PopoverWrap = ({ render, content = '' }) => {

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <React.Fragment>
      {render({
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
        ['aria-haspopup']: true,
        ['aria-owns']: open ? 'mouse-over-popover' : undefined
      })}
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
        onClose={handlePopoverClose}
        disableScrollLock
      >
        {content}
      </Popover>
    </React.Fragment>
  )
}
