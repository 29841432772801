import React from 'react'

import { Button, Nav } from 'reactstrap'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'

import { languages } from '@utils/helpers/languages'
import { useThrottle } from '@utils/hooks/useThrottle'
import { Auth } from '@utils/helpers/auth'
import { pageRegExp, pageFromLinkRegExp } from '@utils/helpers/regExp'

import { MobileMenuIcon } from './MobileMenuIcon'
import permissions from '@utils/helpers/permissions'
import { PopoverWrap } from './PopoverWrap'
import { DropdownLink } from './components/DropdownLink'
import { RenderLink } from '@components/main/components/RenderLink'

export const Header = (props) => {
  const dispatch = useDispatch()

  const { groups, language } = useSelector((state) => state.global)
  const { selectedPage } = useSelector((state) => state.header)

  const availableLinks = permissions.filter((permissionLink) => permissionLink.hasPermission(groups))

  const [navWidth, setNavWidth] = React.useState(0)
  const [navLinksWidth, setNavLinksWidth] = React.useState(0)
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  const [windowWidth, setWindowWidth] = React.useState(window.outerWidth)
  const [title, setTitle] = React.useState('ReportBot')
  const [visibleLinks, setVisibleLinks] = React.useState([])
  const [dropdownLinks, setDropdownLinks] = React.useState([])
  const [linkWidthUpdated, setLinkWidthUpdated] = React.useState(false)

  const navRef = React.useRef(null)
  const navLinksRef = React.useRef(null)
  const allLinksWidth = React.useRef(
    languages.reduce(
      (languageLinksWidth, language) => ({
        ...languageLinksWidth,
        [language]: new Map()
      }), {})
  )

  const username = Auth.getUsername()

  const handleLinkClick = (permissionLink) => {
    const result = pageFromLinkRegExp.exec(permissionLink.link)
    const linkPage = result === null ? null : result[1]
    dispatch({ type: 'SET_SELECTED_PAGE', payload: linkPage })
    setShowMobileMenu(false)
    setTitle(permissionLink.label)
  }

  const isUrlSelected = (link) => {
    const result = pageFromLinkRegExp.exec(link)
    const linkPage = result === null ? null : result[1]
    return linkPage === selectedPage
  }

  const changeDocumentTitle = () => {
    let documentTitle = title
    documentTitle = documentTitle.charAt(0).toUpperCase() + documentTitle.slice(1) + ' - Reportbot'
    document.title = documentTitle
  }

  React.useEffect(() => {
    changeDocumentTitle()
  }, [title])

  const updateNavsWidth = () => {
    if (window.outerWidth > 912) {
      if (navRef.current && navWidth !== navRef.current.offsetWidth)
        setNavWidth(navRef.current.offsetWidth)
      if (
        navLinksRef.current &&
        navLinksWidth !== navLinksRef.current.offsetWidth &&
        navLinksRef.current.offsetWidth !== 0
      )
        setNavLinksWidth(navLinksRef.current.offsetWidth)
    }
    setWindowWidth(window.outerWidth)
  }

  const updateNavLinks = () => {
    if (navLinksRef.current && navRef.current && navWidth && window.outerWidth > 912) {
      let currentNavLinksLength = 0
      const dropdown = []
      for (let item of navLinksRef.current.children)
        if (item.id === 'dropdown') dropdown.push(item)
      const dropdownWidth = dropdown.length > 0 ? dropdown[0].offsetWidth : 0
      const newVisibleLinks = []
      const newDropdownLinks = []
      allLinksWidth.current[language].forEach((value, key) => {
        if (!key.isAdditional) {
          currentNavLinksLength += value
          currentNavLinksLength < navWidth - dropdownWidth
            ? newVisibleLinks.push(key)
            : newDropdownLinks.push(key)
        }
      })
      availableLinks.filter((permissionLink) =>
        permissionLink.isAdditional && permissionLink.label !== 'Settings'
      ).forEach((permissionLink) => newDropdownLinks.push(permissionLink))
      visibleLinks.length !== newVisibleLinks.length && setVisibleLinks(newVisibleLinks)
      dropdownLinks.length !== newDropdownLinks.length && setDropdownLinks(newDropdownLinks)
    }
  }

  const throttledUpdateNavWidth = useThrottle(updateNavsWidth, 500)
  window.addEventListener('resize', throttledUpdateNavWidth)

  const getAllLinksWidth = () => {
    if (
      navLinksRef.current &&
      navLinksRef.current.children.length > 0 &&
      allLinksWidth.current[language].size === 0 &&
      linkWidthUpdated === false
    ) {
      for (let i = 0; i < navLinksRef.current.children.length; i++)
        if (navLinksRef.current.children[i].id !== 'dropdown')
          allLinksWidth.current[language].set(visibleLinks[i], navLinksRef.current.children[i].offsetWidth)
      setLinkWidthUpdated(true)
    }
  }

  const languageChange = () => {
    if (allLinksWidth.current[language].size === 0) {
      setVisibleLinks(availableLinks.filter((permissionLink) => !permissionLink.isAdditional))
      setDropdownLinks(availableLinks.filter((permissionLink) => permissionLink.isAdditional))
      setLinkWidthUpdated(false)
    }
  }

  const isActiveLanguage = (dropdown) => {
    return dropdown === language
  }

  const getInitialSelectedPage = () => {
    const result = pageRegExp.exec(window.location.href)
    const page = result === null ? null : result[1]
    page && setTitle(page)
    if (page !== selectedPage)
      dispatch({ type: 'SET_SELECTED_PAGE', payload: page })
  }

  React.useEffect(() => {
    getInitialSelectedPage()
  }, [window.location.href])

  React.useEffect(() => {
    getAllLinksWidth()
    throttledUpdateNavWidth
    updateNavLinks()
  }, [navLinksWidth, navWidth])

  React.useEffect(() => {
    if (visibleLinks.length === 0 && availableLinks.length !== 0)
      setVisibleLinks(availableLinks.filter((permissionLink) => !permissionLink.isAdditional))
  }, [availableLinks])

  React.useEffect(() => {
    languageChange()
  }, [language])

  return (
    <React.Fragment>
      <div className='header-wrapper'>
        <div className='header-main'>
          <div className="header-nav" ref={navRef}>
            <ul
              className="header-links nav nav-pills"
              ref={navLinksRef}
              style={windowWidth <= 912 ? { display: 'none' } : null}
            >
              {visibleLinks.map((permissionLink, index) => (
                <RenderLink
                  {...props}
                  key={`${permissionLink.link}_${index}`}
                  permissionLink={permissionLink}
                  isUrlSelected={isUrlSelected}
                  handleLinkClick={handleLinkClick}
                />
              ))}
              {dropdownLinks.length > 0 && (
                <UncontrolledDropdown nav id="dropdown">
                  <DropdownToggle
                    className="nav-link header-visible-link header__dropdown-button"
                    color="empty"
                  >
                    <div className="glyph-icon simple-icon-arrow-down" />
                  </DropdownToggle>
                  <DropdownMenu className='header-dropdown-menu'>
                    {dropdownLinks.map((permissionLink, index) => (
                      <DropdownLink
                        {...props}
                        key={`${permissionLink.link}_${index}`}
                        permissionLink={permissionLink}
                        isUrlSelected={isUrlSelected}
                        handleLinkClick={handleLinkClick}
                      />
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </ul>
            <div
              className="header-links-mobile"
              style={windowWidth > 912 ? { display: 'none' } : null}
            >
              <div
                className="header-menu-button"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <MobileMenuIcon isMenuShowed={showMobileMenu} />
              </div>
              <Nav className="header-current-link nav-pills">
                {availableLinks.length && (
                  <RenderLink
                    {...props}
                    permissionLink={
                      availableLinks.filter((permissionLink) => isUrlSelected(permissionLink.link))[0]
                    }
                    isUrlSelected={isUrlSelected}
                    handleLinkClick={handleLinkClick}
                  />
                )}
              </Nav>
            </div>
          </div>
          <div className="header-control">
            <div className="language-control">
              <UncontrolledDropdown>
                <DropdownToggle
                  color="primary"
                  size="sm"
                  outline
                  className='language-dropdown-toggle'
                >
                  <span>{language}</span>
                </DropdownToggle>
                <DropdownMenu className='language-dropdown-menu'>
                  {languages.map((language, index) => (
                    <DropdownItem
                      className={'language-dropdown-item'}
                      disabled={isActiveLanguage(language)}
                      onClick={() => dispatch({ type: 'GLOBAL_SET_LANGUAGE', language: language })}
                      key={index}
                    >
                      {language}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <PopoverWrap
              render={(props) => (
                <span className="username-control" {...props}>
                  {username}
                </span>
              )}
              content={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {groups !== null && groups.map((item, index) => (<span key={`${item}_${index}`}>{item}</span>))}
                </div>
              }
            />
            <Button color="primary" size="sm" outline onClick={props.logout}>
              <FormattedMessage defaultMessage="Log out" id="PlBReU" />
            </Button>
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div className="mobile-menu">
          <Nav className="mobile-menu__links nav-pills">
            {availableLinks.map((permissionLink, index) => (
              <RenderLink
                {...props}
                key={`${permissionLink.link}_${index}`}
                permissionLink={permissionLink}
                isUrlSelected={isUrlSelected}
                handleLinkClick={handleLinkClick}
              />
            ))}
          </Nav>
        </div>
      )}
    </React.Fragment>
  )
}
